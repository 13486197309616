<template>
  <div class="update-export-stock">
    <KTCodePreview v-bind:title="'Thông tin phiếu xuất kho'">
      <template v-slot:toolbar>
        <div
          class="row"
          v-if="checkPermission('STOCK_EXPORT_UPDATE')"
        >
          <div>
            <b-row
              class="mb-4 ml-4"
              style="vertical-align: middle; display: inline"
            >
              <div
                class="custom-control custom-switch"
                v-if="!isAuto"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitch2"
                  v-model="isEdit"
                />
                <label
                  class="custom-control-label text-lable"
                  for="customSwitch2"
                  style="font-size: 14px; font-weight: 500"
                >Chỉnh sửa</label>
              </div>
            </b-row>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row
          class="col-md-12 mb-1 m-0 p-0"
          v-if="idNhanh"
        >
          <div
            class="alert-header"
            style="width: 100%"
          >
            Phiếu được đồng bộ từ Nhanh:
            <span class="text-primary">{{ idNhanh }}</span>
          </div>
        </b-row>
        <b-row>
          <b-col
            lg="6"
            md="6"
            sm="12"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="Mã phiếu:"
                  label-class="font-weight-bolder"
                >
                  <p>{{ codeStockBill }}</p>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Ngày tạo:"
                  label-class="font-weight-bolder"
                >
                  <p>{{ dateCreate }}</p>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group class="required-control">
                  <label>Ngày xuất kho:</label>
                  <date-picker
                    v-bind:disabled="status != 1"
                    placeholder="Chọn ngày xuất kho"
                    class="mb-2 mt-2 form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="$v.form.dateImport.$model"
                    :state="validateState('dateImport')"
                    aria-describedby="input-date-feedback"
                  ></date-picker>
                  <b-form-invalid-feedback id="input-live-feedback">Vui lòng chọn ngày xuất kho</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Tổng tiền:"
                  label-class="font-weight-bolder"
                >
                  <p>{{ formatMoney(totalPrice) }}</p>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Người tạo:"
                  label-class="font-weight-bolder"
                >
                  <p>{{ createBy }}</p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group class="required-control">
                  <label>Kho:</label>
                  <b-form-select
                    class="select-style"
                    :disabled="true"
                    v-model="$v.form.inventorySelected.$model"
                    :options="listInventories"
                    size="sm"
                    required
                    value-field="id"
                    text-field="name"
                    @change="onChangeStore"
                  >
                    <template v-slot:first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >Chọn kho</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Từ phiếu:"
                  label-class="font-weight-bolder"
                  v-if="isAuto"
                >
                  <p>{{ relatedStockSlipCode }}</p>
                </b-form-group>
                <b-form-group
                  v-else
                  :disabled="!isEdit"
                  class="required-control"
                  v-show="isShowProviderInput"
                >
                  <label>Nhà cung cấp:</label>
                  <b-input-group>
                    <vue-autosuggest
                      class="border-radius-none"
                      :suggestions="filteredOptionsProvider"
                      @selected="onSelectedProvider"
                      :limit="200"
                      @input="onInputChangeProvider"
                      :input-props="{
                        class: 'autosuggest__input',
                        placeholder: 'Chọn nhà cung cấp',
                        style: 'border-radius:0px!important',
                      }"
                      v-model="searchProvider"
                    >
                      <div
                        slot-scope="{ suggestion }"
                        style="display: flex; align-items: center"
                      >
                        <div style="{ display: 'flex', color: 'navyblue'}">
                          {{ suggestion.item.suggestionName }}
                        </div>
                      </div>
                    </vue-autosuggest>
                    <b-input-group-append>
                      <span
                        @click="showOriginalModel = !showOriginalModel"
                        class="input-group-text sub-add"
                      >
                        <i class="fas fa-plus"></i>
                      </span>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Nội dung:"
                  label-class="font-weight-bolder"
                  :disabled="!isEdit"
                >
                  <b-form-textarea
                    size="sm"
                    v-model="notes"
                    :placeholder="'Thêm nội dung...'"
                    :rows="6"
                    :max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <!-- INFO VAT INPUT CONTROLS -->
          <b-col
            lg="3"
            md="3"
            sm="12"
            v-if="isShowVatInput"
          >
            <b-form-group>
              <label>Chiết khấu:</label>
              <b-row>
                <b-col
                  class="pr-0"
                  cols="5"
                >
                  <b-form-select
                    class="select-style"
                    v-model="selectDiscountType"
                    :options="listAmountType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                  >
                  </b-form-select>
                </b-col>
                <b-col
                  class="pl-0"
                  cols="7"
                >
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="discountAmount"
                    v-mask="formater.currency"
                    placeholder="Chiết khấu"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <label>VAT:</label>
              <b-row>
                <b-col
                  class="pr-0"
                  cols="5"
                >
                  <b-form-select
                    class="select-style"
                    v-model="selectVatType"
                    :options="listAmountType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                    :disabled="!isEdit"
                  >
                  </b-form-select>
                </b-col>
                <b-col
                  class="pl-0"
                  cols="7"
                >
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="vatAmount"
                    v-mask="formater.currency"
                    placeholder="VAT"
                    :disabled="!isEdit"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <label>Số hóa đơn VAT:</label>
              <b-form-input
                class="input-style"
                size="sm"
                type="text"
                v-model="vatCode"
                placeholder="Số hóa đơn VAT"
                :disabled="!isEdit"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="'Ngày xuất VAT'"
              :disabled="!isEdit"
            >
              <date-picker
                placeholder="Chọn ngày xuất VAT"
                class="form-control form-control-sm"
                :config="dpConfigs.date"
                v-model="vatDate"
              ></date-picker>
            </b-form-group>
          </b-col>
          <!-- PAYMENT INPUT CONTROLS -->
          <b-col
            lg="3"
            md="3"
            sm="12"
            v-if="isShowPaymentInput"
          >
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group :disabled="!isEdit">
                  <label>Tiền mặt</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="cashAmount"
                    placeholder="Nhập số tiền mặt"
                    v-mask="formater.currency"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group :disabled="!isEdit">
                  <label>Tài khoản tiền mặt</label>
                  <vue-autosuggest
                    class="border-radius-none"
                    aria-describedby="input-store-live-feedback"
                    :suggestions="filteredOptionsCash"
                    @selected="onSelectedCash"
                    :limit="200"
                    @input="onInputChangeCash"
                    :input-props="{
                      class: 'autosuggest__input',
                      placeholder: 'Chọn tài khoản tiền mặt',
                      style: 'border-radius:0px!important',
                    }"
                    v-model="searchCash"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      <div style="{ display: 'flex', color: 'navyblue'}">
                        {{ suggestion.item.suggestionName }}
                      </div>
                    </div>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group :disabled="!isEdit">
                  <label>Tiền chuyển khoản</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="transferAmount"
                    placeholder="Nhập số tiền"
                    v-mask="formater.currency"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group :disabled="!isEdit">
                  <label>Tài khoản chuyển khoản:</label>
                  <vue-autosuggest
                    class="border-radius-none"
                    aria-describedby="input-store-live-feedback"
                    :suggestions="filteredOptionsTransfer"
                    @selected="onSelectedTransfer"
                    :limit="200"
                    @input="onInputChangeTransfer"
                    :input-props="{
                      class: 'autosuggest__input',
                      placeholder: 'Chọn tài khoản chuyển khoản',
                      style: 'border-radius:0px!important',
                    }"
                    v-model="searchTransfer"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      <div style="{ display: 'flex', color: 'navyblue'}">
                        {{ suggestion.item.suggestionName }}
                      </div>
                    </div>
                  </vue-autosuggest>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >
                <b-form-group :disabled="!isEdit">
                  <label>Ngày thanh toán:</label>
                  <date-picker
                    placeholder="Chọn ngày thanh toán"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="payDate"
                  ></date-picker>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          class="mb-4 col-md-12"
          style="padding-left: 0px"
        >
          <b-col class="col-md-2">
            <b-form-select
              class="select-style"
              v-model="selectTypeSearch"
              :options="listTypeSearch"
              size="sm"
              value-field="id"
              text-field="name"
              :disabled="!isEdit"
            ></b-form-select>
          </b-col>
          <b-col class="col-md-4">
            <vue-autosuggest
              v-model="searchProduct"
              :suggestions="filteredOptionsProduct"
              @selected="onSelectedProduct"
              :limit="10"
              @input="onInputChangProduct"
              :input-props="
                isEdit
                  ? {
                      class: 'autosuggest__input',
                      placeholder: 'Tìm kiếm',
                      disabled: false,
                    }
                  : {
                      class: 'autosuggest__input',
                      placeholder: 'Tìm kiếm',
                      disabled: true,
                    }
              "
              :should-render-suggestions="
                (size, loading) => size >= 0 && !loading && searchProduct !== ''
              "
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.productName }}
                </div>
              </div>
            </vue-autosuggest>
          </b-col>
        </b-row>
        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table class="
            table table-bordered table-vertical-center table-hover
            col-12
            mt-4
          ">
          <thead>
            <tr>
              <th
                scope="col"
                class="title-center"
                style="width: 20%"
              >
                Tên sản phẩm
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 5%"
              >Tồn</th>
              <th
                scope="col"
                class="title-center"
                style="width: 19%"
              >IMEI</th>
              <th
                scope="col"
                class="title-center"
                style="width: 14%"
              >
                Số lượng
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 20%"
              >
                Đơn giá
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 15%"
              >
                Thành tiền
              </th>
              <th
                scope="col"
                class="title-center"
                style="width: 10%"
                v-if="isEdit"
              ></th>
            </tr>
          </thead>

          <tbody
            v-for="item in listProductStock"
            :key="item.id"
          >
            <ProductStock
              v-bind:productItem="item"
              v-bind:status="status"
              v-bind:stockSlipType="stockSlipType"
              v-on:deleteItem="deleteItemInArray"
              v-on:update="updateItem"
              v-bind:editState="isEdit"
            />
          </tbody>
          <tr>
            <td
              :colspan="3"
              class="tdTextAlignPrice"
            >Tổng</td>
            <td class="tdTextAlignPrice">{{ formatMoney(totalQuantity) }}</td>
            <td></td>
            <td class="tdTextAlignPrice">{{ formatMoney(totalPrice) }}</td>
            <td v-if="isEdit"></td>
          </tr>
        </table>
        <hr
          class="hr-text"
          data-content="Bút Toán"
          style="font-weight: 600"
          v-if="stockSlipType !== STOCK_SLIP_TYPE.EXPORT_WARRANTY_TO_PROVIDER"
        />
        <b-row v-if="codeStockBill !== ''">
          <b-col
            md="12"
            lg="12"
            sm="12"
            v-if="stockSlipType !== STOCK_SLIP_TYPE.EXPORT_WARRANTY_TO_PROVIDER"
          >
            <StockSlipTransactions :stockCode="codeStockBill" />
          </b-col>
        </b-row>

        <b-modal
          v-model="showOriginalModel"
          hide-footer
          title="Thêm mới nhà cung cấp"
        >
          <b-row class="mb-5">
            <b-col>
              <label class="labelInput">Tên nhà cung cấp:</label>
              <b-form-input
                v-model="providerName"
                size="sm"
                placeholder="Nhập tên nhà cung cấp"
              ></b-form-input>
            </b-col>
            <b-col>
              <label class="labelInput">Số điện thoại nhà cung cấp:</label>
              <b-form-input
                v-model="providerPhone"
                size="sm"
                placeholder="Nhập số điện thoại nhà cung cấp"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="createProvider()"
              >Lưu</b-button>
              <b-button
                @click="showOriginalModel = !showOriginalModel"
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
              >Hủy</b-button>
            </b-col>
          </b-row>
        </b-modal>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onSubmit"
          v-if="isEdit"
        >Lưu</b-button>
        <b-button
          :style="
            isEdit
              ? 'margin-left: 10px; font-weight: 600; width: 100px'
              : 'font-weight: 600; width: 100px'
          "
          variant="primary"
          size="sm"
          @click="exportExcel"
        >Xuất excel</b-button>
        <router-link
          to="/export-stocks"
          tag="button"
        >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
          >Trở về</b-button>
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss';

.update-export-stock {
  .title-center {
    text-align: center;
  }

  .autosuggest__results ul {
    list-style: none;
    cursor: pointer;
    margin-bottom: 0;
  }

  .autosuggest__results {
    position: absolute;
    background-color: #fff;
    z-index: 1;
    width: 100%;
    border: 1px solid #e0e0e0;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: calc(1.35em + 1.1rem + 2px);
    padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: 0.28rem;
  }

  .autosuggest__input:focus {
    outline: none !important;
    border: 1px solid #92c5f9;
  }

  .autosuggest__results-item:hover {
    background-color: #ddd;
  }

  .autosuggest__results ul li {
    padding: 8px;
  }
  .input-group-append {
    cursor: pointer;
  }

  .input-group-append:hover i {
    color: #3699ff;
  }

  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
    font-weight: 600;
  }
  .alert-header {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ProductStock from '@/view/components/ProductStock';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import {
  currencyMask,
  makeToastFaile,
  makeToastSuccess,
  unMaskPrice,
} from '@/utils/common';
import axios from 'axios';
import { BASE_URL, TIME_TRIGGER } from '@/utils/constants';
import decounce from 'debounce';
import { AMOUNT_TYPE } from '@/utils/enum';
import { cmdUrl } from '@/utils/apiUrl';
import _ from 'lodash';
import StockSlipTransactions from '@/view/components/StockSlipTransactions';
import localData from '@/utils/saveDataToLocal';
import { STOCK_SLIP_TYPE } from '@/utils/enum';

export default {
  mixins: [validationMixin],
  data() {
    return {
      STOCK_SLIP_TYPE,
      stockSlipType: null,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px',
      },
      form: {
        inventorySelected: null,
        providerSelected: null,
        dateImport: '',
      },
      isNew: true,
      idStock: '',
      codeStockBill: '',
      dateCreate: '',
      listInventories: [],
      listProviders: [],
      count: 0,
      notes: '',
      createBy: '',
      status: 0,
      listType: [
        { id: 1, name: 'Xuất trả hàng nhà cung cấp' },
        { id: 2, name: 'Xuất kho bán hàng' },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        // {
        //   id: 2,
        //   name: 'Tìm theo IMEI',
        // },
      ],
      selectTypeSearch: 1,
      listProductStock: [],
      selectedClassProduct: null,
      timer: null,
      showOriginalModel: false,
      providerName: '',
      providerPhone: '',
      filteredOptionsProvider: [],
      optionsProvider: [
        {
          data: [],
        },
      ],
      searchProvider: '',
      idNhanh: null,
      isEdit: false,
      isAuto: false,
      cashAmount: 0,
      cashAccountCode: '',
      transferAmount: 0,
      transferAccountCode: '',
      payDate: null,
      filterModel: {
        cashAccountants: [],
        transferAccountants: [],
      },
      formater: {
        currency: currencyMask,
      },
      filteredOptionsCash: [],
      optionsCash: [
        {
          data: [],
        },
      ],
      searchCash: '',
      filteredOptionsTransfer: [],
      optionsTransfer: [
        {
          data: [],
        },
      ],
      searchTransfer: '',
      selectDiscountType: 1,
      listAmountType: [
        {
          id: 1,
          name: 'Tiền mặt',
        },
        {
          id: 2,
          name: '%',
        },
      ],
      discountAmount: 0,
      selectVatType: 2,
      vatAmount: 0,
      vatCode: '',
      vatDate: '',
      relatedStockSlipCode: '',
    };
  },
  validations: {
    form: {
      inventorySelected: { required },
      providerSelected: { required },
      dateImport: { required },
    },
  },
  components: {
    KTCodePreview,
    ProductStock,
    StockSlipTransactions,
  },
  created() {
    this.fetchStore();
    this.fetchProvider();
    this.getStockById();
    this.fetchAccountants('cash');
    this.fetchAccountants('transfer');
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      const discountValue = this.discountAmount
        ? unMaskPrice(this.discountAmount)
        : 0;
      let discountAmount = discountValue;
      if (this.selectDiscountType === AMOUNT_TYPE.PERCENT) {
        discountAmount = (discountValue * total) / 100;
      }

      total = total - discountAmount;
      const vatValue = this.vatAmount ? unMaskPrice(this.vatAmount) : 0;

      let vatAmount = vatValue;
      if (this.selectVatType === AMOUNT_TYPE.PERCENT) {
        vatAmount = (vatValue * total) / 100;
      }

      total = parseInt(total) + parseInt(vatAmount);
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.listProductStock.length; index++) {
        const element = this.listProductStock[index];
        total += element.quantity * 1;
      }
      return total;
    },
    isShowVatInput() {
      const skipTypes = [
        STOCK_SLIP_TYPE.EXPORT_WARRANTY_TO_PROVIDER,
        STOCK_SLIP_TYPE.EXPORT_STOCK_CHANGE_PRODUCT_TYPE,
      ];
      return !skipTypes.includes(this.stockSlipType);
    },
    isShowPaymentInput(){
      const skipTypes = [
        STOCK_SLIP_TYPE.EXPORT_WARRANTY_TO_PROVIDER,
        STOCK_SLIP_TYPE.EXPORT_STOCK_CHANGE_PRODUCT_TYPE,
      ];
      return !skipTypes.includes(this.stockSlipType);
    },
    isShowProviderInput(){
      return this.stockSlipType !== STOCK_SLIP_TYPE.EXPORT_STOCK_CHANGE_PRODUCT_TYPE
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu xuất kho', route: '/export-stocks' },
      { title: 'Cập nhật phiếu xuất kho' },
    ]);
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      let imeiCode = '';
      let quantity = 0;
      if (this.selectTypeSearch === 2) {
        imeiCode = option.item.imeiCode;
        quantity = 1;
        this.count++;
        const pro = {
          id: this.count,
          name: this.selectedClassProduct.productName,
          productCode: this.selectedClassProduct.productCode,
          barCode: this.selectedClassProduct.barCode,
          totalQuantityInStock:
            this.selectedClassProduct.totalQuantityInStock != null
              ? this.selectedClassProduct.totalQuantityInStock
              : 0,
          IMEI: imeiCode,
          proId: this.selectedClassProduct.id,
          price: this.selectedClassProduct.originalPrice,
          quantity: quantity,
          totalPrice: 0,
          productType: this.selectedClassProduct.productType,
          discount: 0,
        };
        this.listProductStock.unshift(pro);
      } else if (this.selectedClassProduct.productType === 1) {
        quantity = 1;
        let index = this.listProductStock.findIndex(
          (item) => item.proId === this.selectedClassProduct.id,
        );
        if (index === -1) {
          this.count++;
          const pro = {
            id: this.count,
            name: this.selectedClassProduct.productName,
            productCode: this.selectedClassProduct.productCode,
            barCode: this.selectedClassProduct.barCode,
            totalQuantityInStock:
              this.selectedClassProduct.totalQuantityInStock != null
                ? this.selectedClassProduct.totalQuantityInStock
                : 0,
            IMEI: imeiCode,
            proId: this.selectedClassProduct.id,
            price: this.selectedClassProduct.originalPrice,
            quantity: quantity,
            totalPrice: 0,
            productType: this.selectedClassProduct.productType,
            discount: 0,
          };
          this.listProductStock.unshift(pro);
        } else {
          this.listProductStock[index].quantity =
            this.listProductStock[index].quantity + quantity;
        }
      } else if (this.selectedClassProduct.productType === 2) {
        quantity = 0;
        this.count++;
        const pro = {
          id: this.count,
          name: this.selectedClassProduct.productName,
          productCode: this.selectedClassProduct.productCode,
          barCode: this.selectedClassProduct.barCode,
          totalQuantityInStock:
            this.selectedClassProduct.totalQuantityInStock != null
              ? this.selectedClassProduct.totalQuantityInStock
              : 0,
          IMEI: imeiCode,
          proId: this.selectedClassProduct.id,
          price: this.selectedClassProduct.originalPrice,
          quantity: quantity,
          totalPrice: 0,
          productType: this.selectedClassProduct.productType,
          discount: 0,
        };
        this.listProductStock.unshift(pro);
      }
      this.searchProduct = option.item.productName;
      this.searchProduct = '';
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    filterProduct(text) {
      const filteredData = this.optionsProduct[0].data
        .filter((item) => {
          if (item.productName === null) {
            item.productName = '';
          }
          if (item.productCode === null) {
            item.productCode = '';
          }
          if (item.barCode === null) {
            item.barCode = '';
          }
          if (this.selectTypeSearch === 1) {
            return (
              item.productName.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
              item.productCode.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
              item.barCode.toLowerCase().indexOf(text.toLowerCase()) > -1
            );
          } else {
            return item.imeiCode.toLowerCase().indexOf(text.toLowerCase()) > -1;
          }
        })
        .slice(0, this.limit);

      this.filteredOptionsProduct = [
        {
          data: filteredData,
        },
      ];
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    getStockById() {
      const id = this.$route.query.id;
      ApiService.get(`stocks/${id}`)
        .then(({ data }) => {
          const dataRes = data.data;
          this.stockSlipType = dataRes.type;
          this.codeStockBill = dataRes.code;
          this.relatedStockSlipCode = dataRes.relatedStockCode;
          this.dateCreate = moment(dataRes.createdAt).format(
            'HH:mm DD/MM/YYYY',
          );
          this.$v.form.dateImport.$model = moment(dataRes.exportDate).format(
            'DD-MM-YYYY',
          );
          this.$v.form.inventorySelected.$model = dataRes.storeId;
          this.$v.form.providerSelected.$model = dataRes.providerId;
          this.searchProvider = dataRes.providerName;
          this.notes = dataRes.description;
          this.createBy = dataRes.createdBy;
          this.status = dataRes.status;
          this.idStock = dataRes.id;
          this.idNhanh = dataRes.idNhanh;
          this.isAuto = dataRes.isAuto;
          this.cashAmount = dataRes.cashAmount;
          this.cashAccountCode = dataRes.cashAccountCode;
          this.searchCash = dataRes.cashAccName;
          this.transferAmount = dataRes.transferAmount;
          this.transferAccountCode = dataRes.transferAccountCode;
          this.searchTransfer = dataRes.transferAccName;
          this.payDate = dataRes.payDate
            ? moment(dataRes.payDate).format('DD-MM-YYYY')
            : '';
          this.vatDate = dataRes.vatDate
            ? moment(dataRes.vatDate).format('DD-MM-YYYY')
            : '';
          this.vatAmount = dataRes.vatAmount;
          this.selectVatType = dataRes.vatType;
          this.vatCode = dataRes.vatCode;
          this.discountAmount = dataRes.discountAmount;
          this.selectDiscountType = dataRes.discountType;
          this.listProductStock = [];
          this.listProductStock = dataRes.listDetail.map((element) => {
            return {
              id: element.id,
              name: element.productName,
              productCode: element.productCode,
              barCode: element.barCode,
              IMEI: element.productImei
                .replace(/[,]/g, '\n')
                .replace(/[ ]/g, ''),
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
              productType: element.productType,
              totalQuantityInStock: element.totalQuantityInStock,
              warrantyQuantity: element.warrantyQuantity,
              discount: 0,
            };
          });
          this.fetchAccountants('cash');
        })
        .catch((response) => {
          makeToastFaile(response);
        });
    },
    fetchStore() {
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.get(
        `stocks/get-product-by-store?storeId=${this.$v.form.inventorySelected.$model}&searchProduct=${textSearch}`,
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          let nextSearchProduct = this.searchProduct;
          this.fetchProduct(nextSearchProduct);
        }
      });
    },
    fetchProvider() {
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProviders = data.data.providers;
        this.listProviders.map((element) => {
          const provider = {
            id: element.id,
            name: element.name,
            suggestionName: element.name,
          };
          this.optionsProvider[0].data.push(provider);
        });
        let tmp = [{ data: this.optionsProvider[0].data }];
        this.filteredOptionsProvider = tmp;
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listProductStock.length; i++) {
        if (this.listProductStock[i].id === id) {
          this.listProductStock.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.listProductStock.forEach((element, index) => {
        if (element.id === item.id) {
          this.listProductStock[index].name = item.name;
          this.listProductStock[index].IMEI = item.IMEI;
          this.listProductStock[index].proId = item.proId;
          this.listProductStock[index].price = item.price;
          this.listProductStock[index].quantity = parseInt(item.quantity);
          this.listProductStock[index].totalPrice = item.totalPrice;
        }
      });
    },
    getListDetailStock() {
      this.listProductStock = [];
      ApiService.get(`stocks/list-stock-detail/${this.idStock}`).then(
        ({ data }) => {
          data.data.forEach((element) => {
            let dataItem = {
              id: element.id,
              name: element.productName,
              IMEI: element.productImei
                .replace(/[,]/g, '\n')
                .replace(/[ ]/g, ''),
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
            };
            this.listProductStock.push(dataItem);
          });
        },
      );
    },
    onSubmit: async function () {
      const listPro = this.listProductStock.map((element) => {
        return {
          id: element.id,
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
          discount: 0,
        };
      });

      const data = {
        code: this.codeStockBill,
        id: this.idStock,
        storeId: this.$v.form.inventorySelected.$model,
        providerId: this.$v.form.providerSelected.$model,
        description: this.notes,
        importDate: this.$v.form.dateImport.$model
          ? moment(this.$v.form.dateImport.$model, 'DD/MM/YYYY').format(
              'yyyy-MM-DD',
            )
          : '',
        listProduct: listPro,
        cashAmount: unMaskPrice(this.cashAmount),
        cashAccountCode: this.cashAccountCode,
        transferAmount: unMaskPrice(this.transferAmount),
        transferAccountCode: this.transferAccountCode,
        payDate: this.payDate
          ? moment(this.payDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        vatDate: this.vatDate
          ? moment(this.vatDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        vatType: this.selectVatType,
        vatAmount: this.vatAmount ? unMaskPrice(this.vatAmount) : 0,
        vatCode: this.vatCode,
        discountType: this.selectDiscountType,
        discountAmount: this.discountAmount
          ? unMaskPrice(this.discountAmount)
          : 0,
      };
      ApiService.put('stock-slips/export-stock-slip', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.$router.push({
              path: '/export-stocks',
            });
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch((response) => {
          makeToastFaile(response);
        });
    },
    onChangeStore() {
      this.listProductStock = [];
    },
    fetchProductImei() {
      ApiService.get(
        `stocks/get-product-by-store-imei?storeId=${this.$v.form.inventorySelected.$model}`,
      ).then(({ data }) => {
        this.optionsProduct[0].data = [];
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
      });
    },
    exportExcel: async function () {
      let baseUrl = BASE_URL;
      //let baseUrl = 'http://localhost:3000/api/';
      let url = `${baseUrl}stocks/export-excel/${this.idStock}?type=2`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex,
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    createProvider: async function () {
      if (!this.providerName) {
        return;
      }
      const data = {
        name: this.providerName,
        phoneNo: this.providerPhone,
        status: 1,
      };

      ApiService.post('providers', data).then((response) => {
        const { status, message } = response.data.data;
        if (status === 1) {
          this.fetchProvider();
          this.showOriginalModel = !this.showOriginalModel;
          makeToastSuccess(message);
        } else {
          this.showOriginalModel = !this.showOriginalModel;
          makeToastFaile(message);
        }
      });
    },
    onSelectedProvider(option) {
      this.searchProvider = option.item.name;
      this.$v.form.providerSelected.$model = option.item.id;
    },
    onInputChangeProvider(text) {
      this.searchProvider = text;
      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptionsProvider = [
        {
          data: filteredData,
        },
      ];
    },
    debounceInputProduct: decounce(function () {
      this.nextSearchProduct();
    }, TIME_TRIGGER),
    nextSearchProduct() {
      if (!this.isSearching) {
        let textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    },
    onSelectedCash(option) {
      this.searchCash = option.item.name;
      this.cashAccountCode = option.item.code;
    },
    onInputChangeCash(text) {
      this.searchCash = text;
      const filteredData = this.optionsCash[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsCash = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedTransfer(option) {
      this.searchTransfer = option.item.name;
      this.transferAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      this.searchTransfer = text;
      const filteredData = this.optionsTransfer[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsTransfer = [
        {
          data: filteredData,
        },
      ];
    },
    fetchAccountants: async function (mode) {
      this.optionsCash[0].data = [];
      let params = {
        type: mode === 'cash' ? '1' : '2',
      };
      let url = cmdUrl.AccountantUrl.byType;
      if (this.$v.form.inventorySelected.$model !== null && mode === 'cash') {
        url = 'accountants/getByStore';
        params = {
          type: mode === 'cash' ? '1' : '2',
          storeId: this.$v.form.inventorySelected.$model,
        };
      }
      ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        _.map(data, (item) => {
          let element = {
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          if (mode === 'cash') {
            this.optionsCash[0].data.push(element);
          } else if (mode === 'transfer') {
            this.optionsTransfer[0].data.push(element);
          }
        });
        let tmpCash = [{ data: this.optionsCash[0].data }];
        this.filteredOptionsCash = tmpCash;
        let tmpTransfer = [{ data: this.optionsTransfer[0].data }];
        this.filteredOptionsTransfer = tmpTransfer;
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
